/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null;
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
	let date;
	if (typeof time === 'object') {
		date = time;
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time);
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000;
		}
		date = new Date(time);
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	};
	const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key];
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value];
		}
		return value.toString().padStart(2, '0');
	});
	return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000;
	} else {
		time = +time;
	}
	const d = new Date(time);
	const now = Date.now();

	const diff = (now - d) / 1000;

	if (diff < 30) {
		return '刚刚';
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + '分钟前';
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前';
	} else if (diff < 3600 * 24 * 2) {
		return '1天前';
	}
	if (option) {
		return parseTime(time, option);
	} else {
		return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
	}
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
	const search = url.split('?')[1];
	if (!search) {
		return {};
	}
	return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * 对象数组根据唯一标识去重
 * @param {array} arr 操作的数组对象
 * @param {string} key 唯一标识
 * @returns {Object}
 */
export function unique(arr, key) {
	//定义常量 res,值为一个Map对象实例
	const res = new Map();
	//返回arr数组过滤后的结果，结果为一个数组;过滤条件是，如果res中没有某个键，就设置这个键的值为1
	return arr.filter(arr => !res.has(arr[key]) && res.set(arr[key], 1));
}

/**
 * 两个对象删除相同对象后，合并一个新数组
 * @param {array} arr1 操作的数组对象
 * @param {array} arr2 操作的数组对象
 * @returns {Object}
 */
export function delComItem(arr1, arr2) {
	var newArr = arr1.filter(item => {
		// 将arr的所有id集合的数组赋值给临时数组,
		//map()会返回一个新数组,并且不会影响原数组
		let arrlist = arr2.map(item2 => item2.value);
		// 返回所有临时数组中没有item.value的item
		return !arrlist.includes(item.value);
	});
	return newArr;
}

/**
 * 将一个没有层级的扁平对象,转换为树形结构({value, children})结构的对象
 * @param {array} tableData - 一个由对象构成的数组,里面的对象都是扁平的
 * @param {array} keys - 一个由字符串构成的数组,字符串为前一数组中对象的key,最终
 * 输出的对象层级顺序为keys中字符串key的顺序
 * @return {array} 保存具有树形结构的对象
 */
export function solution(tableData, keys) {
	const hashTable = {};
	const res = [];
	for (let i = 0; i < tableData.length; i++) {
		let arr = res;
		let cur = hashTable;
		for (let j = 0; j < keys.length; j++) {
			const key = keys[j];
			const filed = tableData[i][key];
			if (!cur[filed]) {
				const pusher = {
					value: filed,
				};
				let tmp;
				if (j !== keys.length - 1) {
					tmp = [];
					pusher.children = tmp;
				}
				cur[filed] = { $$pos: arr.push(pusher) - 1 };
				cur = cur[filed];
				arr = tmp;
			} else {
				cur = cur[filed];
				arr = arr[cur.$$pos].children;
			}
		}
	}
	return res;
}

export function computeTableHeight() {
	let topNav = document.getElementById('topNav');
	let queryForm = document.getElementById('queryForm');
	let footerBox = document.getElementById('footerBox');
	let addBtnRow = document.getElementById('addBtnRow');
	let pagination = document.getElementById('pagination');
	let tabsBox = document.getElementById('tabsBox');
	let topNavHeight = 0;
	let queryFormHeight = 0;
	let footerBoxHeight = 0;
	let addBtnRowHeight = 0;
	let paginationHeight = 0;
	let tabsBoxHeight = 0;
	if (topNav) {
		topNavHeight = topNav.clientHeight;
	}
	if (queryForm) {
		queryFormHeight = queryForm.clientHeight;
	}
	if (footerBox) {
		queryFormHeight = footerBox.clientHeight;
	}
	if (addBtnRow) {
		addBtnRowHeight = addBtnRow.clientHeight;
	}
	if (pagination) {
		paginationHeight = pagination.clientHeight;
	}
	if (tabsBox) {
		tabsBoxHeight = tabsBox.clientHeight;
	}
	let windowHeight = window.innerHeight;
	let tableHeight = windowHeight - topNavHeight - queryFormHeight - footerBoxHeight - addBtnRowHeight - paginationHeight - tabsBoxHeight - 120;
	return tableHeight;
}

//防抖
export function debounce(func, wait) {
	let timer;
	return function () {
		let that = this;
		let args = [...arguments];
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			func.call(that, ...args);
		}, wait);
	};
}
