<template>
    <div class="app-container">
        <el-row>
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="querySelectedValue" v-if="userInfo.userType != 2">
                    <el-input clearable placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select">
                        <el-select v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
                            <el-option label="姓名" value="userName"></el-option>
                            <el-option label="手机号" value="phone"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item prop="state">
                    <el-select clearable v-model="queryForm.state" placeholder="请选择审核状态">
                        <el-option label="待审核" value="0"></el-option>
                        <el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="dataRange">
                    <el-date-picker clearable v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-table v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row>
            <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="queryUserInfo(scope.row.userCode)">{{ scope.row.userName }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center">
                <template slot-scope="scope">
                    {{ scope.row.userPhone }}
                </template>
            </el-table-column>
            <el-table-column label="提现金额（元）" align="center">
                <template slot-scope="scope">
                    {{ scope.row.money / 1000 }}
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="state.AcountStateColorType[scope.row.state]">{{ state.AcctCashState[scope.row.state] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="申请时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button slot="reference" type="text" size="small" @click="review(scope.row.cashId)">{{ userInfo.userType == 1 && scope.row.state == 0 ? "审核" : "查看" }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
        <withdraw-review :cashId="cashId" :withdrawReviewVisible="withdrawReviewVisible" @withdrawReviewClose="withdrawReviewClose" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getAcctcashPageList } from "@/api/acctcash";
import UserInfo from "@/components/UserInfo";
import state from "@/utils/state";
import moment from "moment";
import WithdrawReview from "./components/withdrawReview";

export default {
    components: { UserInfo, WithdrawReview },
    data() {
        return {
            withdrawReviewVisible: false,
            drawerVisible: false,
            userCode: null,
            listLoading: true,
            list: [],
            moment,
            diaLogShow: false,
            cashId: null,
            queryForm: {
                querySelectedType: "userName",
                querySelectedValue: "",
                state: "",
                dataRange: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
            taskState: "1",
        };
    },
    computed: {
        state() {
            return state.state;
        },
        ...mapGetters(["userInfo"]),
    },
    created() {
        this.fetchData();
    },
    methods: {
        withdrawReviewClose(refeshFlag) {
            this.withdrawReviewVisible = false;
            this.cashId = null;
            if (refeshFlag == true) {
                this.fetchData();
            }
        },
        review(cashId) {
            this.cashId = cashId;
            this.withdrawReviewVisible = true;
        },
        closeDrawer() {
            this.drawerVisible = false;
            this.userCode = null;
        },
        queryUserInfo(userCode) {
            this.userCode = userCode;
            this.drawerVisible = true;
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        addAccount() {
            this.diaLogShow = true;
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        queryData() {
            this.fetchData();
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
            };
            if (this.userInfo.userType == 2) {
                params.userCode = this.userInfo.userCode;
            }
            if (queryForm.querySelectedValue && queryForm.querySelectedValue != "") {
                params[queryForm.querySelectedType] = queryForm.querySelectedValue;
            }
            if (queryForm.state != "") {
                params.state = queryForm.state;
            }
            if (queryForm.dataRange && queryForm.dataRange != "") {
                params.maxAddTime = moment(queryForm.dataRange[1]).format("YYYY-MM-DD") + "T" + "23:59:59";
                params.minAddTime = moment(queryForm.dataRange[0]).format("YYYY-MM-DD") + "T" + "00:00:00";
            }
            const _this = this;
            getAcctcashPageList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
    },
};
</script>
