<template>
    <el-drawer append-to-body :close-on-press-escape="false" size="50%" :wrapperClosable="false" :visible="visible" :before-close="handleClose">
        <div class="descriptions-container">
            <el-descriptions>
                <el-descriptions-item label="用户编号">{{ acountInfo.userCode || "--" }}</el-descriptions-item>
                <el-descriptions-item label="用户名">{{ acountInfo.userName || "--" }}</el-descriptions-item>
                <el-descriptions-item label="账号类型">{{ acountInfo.userType ? state.AcountType[acountInfo.userType] : "--" }}</el-descriptions-item>
                <el-descriptions-item label="账号状态">
                    <el-tag size="mini" v-if="acountInfo.state" effect="dark" :type="state.AcountStateColorType[acountInfo.state]">{{ state.AcountState[acountInfo.state] }}</el-tag>
                    <span v-else>--</span>
                </el-descriptions-item>
                <el-descriptions-item label="注册来源">{{ acountInfo.source ? state.AcountSource[acountInfo.source] : "--" }}</el-descriptions-item>
                <el-descriptions-item label="推荐人（上级）">{{ acountInfo.belongToCode || "--" }}</el-descriptions-item>
                <el-descriptions-item label="手机号">{{ acountInfo.phone || "--" }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ moment(acountInfo.addTime).format("YYYY-MM-DD HH:mm:ss") }}</el-descriptions-item>
                <el-descriptions-item label="所在区域">{{ acountInfo.province ? acountInfo.province + acountInfo.city + acountInfo.district : "--" }}</el-descriptions-item>
                <el-descriptions-item label="具体地址" :span="3">{{ acountInfo.address }}</el-descriptions-item>
                <el-descriptions-item v-if="acountInfo.userType == 1" label="负责区域" :span="3" content-class-name="poListContent">
                    <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
                    <div class="treeBox">
                        <el-tree :data="poList" accordion default-expand-all :filter-node-method="filterNode" ref="tree"> </el-tree>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </el-drawer>
</template>
<script>
import { queryUserInfo } from "@/api/user";
import state from "@/utils/state";
import moment from "moment";
import { solution } from "@/utils";
export default {
    props: ["visible", "userCode"],
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
        visible(data) {
            const _this = this;
            if (data) {
                //查询详情
                _this.getUserInfo();
            }
        },
    },
    computed: {
        state() {
            return state.state;
        },
    },
    data() {
        return {
            filterText: "",
            moment,
            acountInfo: {},
            poList: [],
        };
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        getUserInfo() {
            const _this = this;
            let params = {
                userCode: this.userCode,
            };
            const loading = _this.$loading({
                lock: true,
                text: "加载中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
                zIndex: 4000,
                customClass: "loading",
            });
            queryUserInfo(params)
                .then((res) => {
                    loading.close();
                    _this.acountInfo = res.data;
                    if (res.data.userType == 1) {
                        const keys = ["province", "city", "district"];
                        let dataList = res.data.poList;
                        // dataList 是一个扁平的数组，是需要转换成城市三级级联数组才能给级联器使用
                        let list = solution(dataList, keys);
                        list.map((pItem) => {
                            pItem.label = pItem.value;
                            pItem.children.map((cItem) => {
                                cItem.label = cItem.value;
                                cItem.children.map((dItem) => {
                                    dItem.label = dItem.value;
                                });
                            });
                        });
                        _this.poList = list;
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        handleClose() {
            this.acountInfo = {};
            this.$emit("closeDrawer");
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .poListContent {
    width: 90%;
    height: 200px;
    .treeBox {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
}
.descriptions-container {
    padding: 0 32px;
}
</style>
