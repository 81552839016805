import request from '@/utils/request'
/**
 * 查询提现列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    maxAddTime (string, optional): 最大添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    minAddTime (string, optional): 最小添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    userCode (string, optional): 用户编号 ,
    userName (string, optional): 用户姓名 ,
    userPhone (string, optional): 手机号码
 } 
 */
export function getAcctcashPageList (data) {
    return request({
        url: '/acctcash/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 提现申请
 * @param  data 
 * @returns 
 * data:{
    bankAcount (string, optional): 银行账号 ,
    bankName (string, optional): 开户行 ,
    money (integer, optional): 提现金额，厘 ,
    remark (string, optional): 备注 ,
    taskIdList (Array[string], optional): 提现任务编号列表 ,
    type (integer, optional): 操作类型：1 余额 ,
    userCode (string, optional): 用户编号 ,
    userName (string, optional): 用户姓名 ,
    userPhone (string, optional): 手机号码
 } 
 */
export function acctcashAdd (data) {
    return request({
        url: '/acctcash/add',
        method: 'post',
        data
    })
}

/**
 * 余额信息查询
 * @param  data 
 * @returns 
 * data:{
    userCode (string, optional): 用户编号 ,
 } 
 */
export function acctbalanceQuery (data) {
    return request({
        url: '/acctbalance/query',
        method: 'get',
        params: data
    })
}

/**
 * 提现订单详情
 * @param  data 
 * @returns 
 * data:{
    userCode (string, optional): 用户编号 ,
 } 
 */
export function acctcashQuery (data) {
    return request({
        url: '/acctcash/query',
        method: 'get',
        params: data
    })
}

/**
 * 查询提现订单关联任务列表
 * @param  data 
 * @returns 
 * data:{
    cashId (string, optional): 体现订单号 ,
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
 } 
 */
export function acctcashtaskrefPageList (data) {
    return request({
        url: '/acctcashtaskref/queryListByTahkId',
        method: 'post',
        data
    })
}

/**
 * 审核提现抵挡
 * @param  data 
 * @returns 
 * data:{
    cashId (string, optional): 体现订单号 ,
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
 } 
 */
export function acctcashCheck (data) {
    return request({
        url: '/acctcash/Check',
        method: 'post',
        data
    })
}
