<template>
    <el-dialog title="提现审核详情" :close-on-click-modal="false" :visible="withdrawReviewVisible" @close="closeDialog">
        <el-descriptions direction="vertical" border :column="4">
            <el-descriptions-item label="用户姓名">
                <el-button type="text" @click="queryUserInfo(reviewInfo.userCode)">{{ reviewInfo.userName || "--" }}</el-button>
            </el-descriptions-item>
            <el-descriptions-item label="银行卡号">{{ reviewInfo.bankAcount || "--" }}</el-descriptions-item>
            <el-descriptions-item label="开户行"> {{ reviewInfo.bankName || "--" }}</el-descriptions-item>
            <el-descriptions-item label="提现金额（元）"> {{ reviewInfo.money ? reviewInfo.money / 1000 : "--" }}</el-descriptions-item>
            <el-descriptions-item label="任务列表" :span="4">
                <el-table row-key="taskId" v-loading="listLoading" element-loading-text="加载中" :data="taskList" border fit highlight-current-row>
                    <el-table-column label="任务名称" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="任务总金额（元）" align="center">
                        <template slot-scope="scope"> {{ (scope.row.itemCount * scope.row.itemPrice) / 1000 }} </template>
                    </el-table-column>
                    <el-table-column label="超时扣款比例" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.timeoutCutMoney + "%" }}
                        </template>
                    </el-table-column>
                    <el-table-column label="二次采集奖励" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.itemAgainMoney + "%" }}
                        </template>
                    </el-table-column>
                    <el-table-column label="已得金额（元）" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.finalMoney / 1000 }}
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" label="发布时间" align="center">
                        <template slot-scope="scope">
                            {{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </template>
                    </el-table-column>
                    <el-table-column label="领取时间" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">
                            {{ moment(scope.row.receiveTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="4">
                <el-input type="textarea" :readonly="reviewInfo.state != 0" :rows="5" v-model="reviewRemark" placeholder="请输入审核备注（拒绝必填）"></el-input>
            </el-descriptions-item>
        </el-descriptions>
        <span v-if="reviewInfo.state == 0 && userInfo.userType == 1" slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="danger" @click="confirm(2)">拒 绝</el-button>
            <el-button type="primary" @click="confirm(1)">通 过</el-button>
        </span>
        <UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
    </el-dialog>
</template>
<script>
import UserInfo from "@/components/UserInfo";
import { mapGetters } from "vuex";
import moment from "moment";
import { acctcashQuery, acctcashtaskrefPageList, acctcashCheck } from "@/api/acctcash";
export default {
    components: { UserInfo },
    props: ["withdrawReviewVisible", "cashId"],
    watch: {
        withdrawReviewVisible(data) {
            if (data) {
                this.queryInfo();
                this.getTaskList();
            }
        },
    },
    data() {
        return {
            userCode: null,
            drawerVisible: false,
            moment,
            taskList: [],
            queryForm: {
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
            listLoading: false,
            reviewRemark: "",
            reviewInfo: {},
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        closeDrawer() {
            this.drawerVisible = false;
            this.userCode = null;
        },
        queryUserInfo(userCode) {
            this.userCode = userCode;
            this.drawerVisible = true;
        },
        getTaskList() {
            this.listLoading = true;
            const _this = this;
            _this.queryForm.cashId = _this.cashId;
            acctcashtaskrefPageList(this.queryForm)
                .then((res) => {
                    _this.listLoading = false;
                    _this.taskList = res.data.dataList;
                    _this.queryForm.totalCount = res.data.totalCount;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getTaskList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.getTaskList();
        },
        queryInfo() {
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "查询中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            let params = {
                cashId: this.cashId,
            };

            acctcashQuery(params)
                .then((res) => {
                    _this.reviewInfo = res.data;
                    _this.reviewRemark = res.data.reviewRemark;
                    loading.close();
                })
                .catch((err) => {
                    loading.close();
                });
        },
        confirm(type) {
            const _this = this;
            let params = {
                cashId: this.cashId,
                state: type,
                type: 1,
            };
            if (type == 2) {
                if (_this.reviewRemark == "" || !_this.reviewRemark) {
                    _this.$message({
                        message: "请输入审核备注！",
                        type: "warning",
                    });
                    return;
                }
            }
            params.reviewRemark = _this.reviewRemark;
            const loading = _this.$loading({
                lock: true,
                text: "提交中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            acctcashCheck(params)
                .then(() => {
                    loading.close();
                    _this.closeDialog(true);
                })
                .catch((err) => {
                    loading.close();
                    console.log(err);
                });
        },
        closeDialog(flag) {
            this.reviewRemark = "";
            this.taskList = [];
            this.listLoading = false;
            this.$emit("withdrawReviewClose", flag);
        },
    },
};
</script>
<style lang="less" scoped>
.img {
    cursor: pointer;
    width: 100px;
    height: 80px;
}
/deep/ .hide .el-upload--picture-card {
    display: none;
}
</style>
