const state = {
    AcctCashState: {
        0: '待审核',
        1: '审核完成',
        2: '审核拒绝',
        3: '处理中',
        6: '待核实'
    },
    AcountState: {
        1: '正常',
        2: '禁用',
        3: '过期'
    },
    AcountType: {
        1: '区代账号',
        2: '采集账号',
        3: '平台账号'
    },
    AcountStateColorType: {
        0: '',
        1: 'success',
        2: 'danger',
        3: 'info'
    },
    AcountSource: {
        1: '自注册',
        2: '直属下级',
        3: '平台创建'
    },
    idCardState: {
        0: '未实名认证',
        1: '已实名认证',
        2: '实名认证拒绝',
        3: '实名认证中'
    },
    bannerScene: {
        1: '首页'
    },
    bannerLinkUrlState: {
        0: '否',
        1: '是'
    },
    bannerState: {
        1: '显示',
        2: '隐藏'
    }
}

export default {
    state
}